<template>
  <div style="display: inline-block;margin-right: 10px;">
    <el-button type="text" size="medium" @click.stop="onEdit()">关联审批条件</el-button>
    <el-dialog :title="'关联审批条件'" :visible.sync="editParamsShow" :close-on-click-modal="false" append-to-body width="50%"
      center @close="reset"  class="condition_container">
      <section class="main">
        <el-form :model="params" ref="form-ref">
          <div class="radio-box">
            <p>审批流实例条件</p>
            <el-radio-group class="radio-ul" v-model="params.formItemId" @change="formItemVosChange">
              <el-radio v-for="(item, index) in processFormItemVos" :label="item.formItemId" :disabled="item.bind"
                :key="index">{{ item.formItemName }}</el-radio>
            </el-radio-group>
          </div>
          <el-button type="primary" @click="onSubmit('form-ref')">关联</el-button>
          <div class="radio-box">
            <p>工单参数</p>
            <div class="radio-ul">
              <el-radio-group class="radio-ul" v-model="params.templateParamId" @change="ParamVosChange">
                <el-radio v-for="(item, index) in workSheetTemplateParamVos" :label="item.templateParamId"
                  :disabled="item.bind" :key="index">{{ item.paramName }}</el-radio>
              </el-radio-group>
            </div>
          </div>
        </el-form>
      </section>
      <section class="footer">
        <div class="footer-box">
          <ul v-if="params.workFormItemRelationDtoList && params.workFormItemRelationDtoList.length > 0">
            <el-tag class="tag-item" closable v-for="(tag, index) in params.workFormItemRelationDtoList"
              @close="onCloseTag(index)" :key="index">
              {{ tag.formItemName }} → {{ tag.paramName }}
            </el-tag>
          </ul>
          <span v-else>暂无关联对象</span>
        </div>
      </section>

    </el-dialog>

  </div>
</template>
<script>
import { getWorkSheetApproveCondition, saveWorkSheetApproveCondition } from "@/api/examine/model"
export default {
  props: ['row'],
  data() {
    return {
      editParamsShow: false,
      processFormItemVos: [], //预定义变量列表
      workSheetTemplateParamVos: [], //工单参数
      workSheetTypeItem: "",
      params: {
        templateId: '', //模板ID
        processId: '', //流程ID
        // 关联审批
        formItemId: "", //预定义变量
        projectTemplateParamId: "", //项目参数
        templateParamId: "", //工单参数
        workFormItemRelationDtoList: [],
      },
    }
  },
  mounted() {

  },

  methods: {
    onEdit() {
      this.editParamsShow = true
      this.params.templateId = this.row.templateId
      this.params.processId = this.row.processId
      this.getRelationProcessView()
    },
    // 【请求】关联审批回显
    getRelationProcessView() {
      let data = {
        templateId: this.row.templateId,
        processId: this.row.processId,
      };
      getWorkSheetApproveCondition(data).then(res => {
        if (res.isSuccess == 'yes') {
          this.processFormItemVos = res.data.processFormItemVos;
          this.workSheetTemplateParamVos = res.data.workSheetTemplateParamVos;
          this.params.workFormItemRelationDtoList = res.data.paramRelationVos;
        }
      })
    },

    // 【请求】关联审批
    getProcessViewSave() {
      saveWorkSheetApproveCondition(this.params).then(res => {
        if (res.isSuccess == 'yes') {
          this.$message({
            type: 'success',
            message: '操作成功!'
          });
          this.getRelationProcessView();
        }
      })
    },

    // 【监听】移除关联
    onCloseTag(index) {
      this.$confirm('是否要删除该关联吗？', '删除关联', {
        type: 'warning'
      }).then(() => {
        if (this.params.formItemId == this.params.workFormItemRelationDtoList[index].formItemId) {
          this.params.formItemId = "";
          this.params.formItemName = "";
        }
        if (this.params.templateParamId == this.params.workFormItemRelationDtoList[index].templateParamId) {
          this.params.templateParamId = "";
          this.params.paramName = "";
        }
        this.params.workFormItemRelationDtoList.splice(index, 1);
        this.getProcessViewSave();

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      })

    },
    // 【监听】关联
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.params.formItemId == "" || this.params.templateParamId == "") {
            this.$message({
              type: 'warning',
              message: '请选择审批流实例条件或工单参数!'
            });
            return false;
          }
          if (this.params.workFormItemRelationDtoList.find(item => item.formItemId == this.params.formItemId || item.templateParamId == this.params.templateParamId)) {
            this.$message({
              type: 'warning',
              message: '该关联已存在!'
            });
            return false;
          }
          let obj = {
            formItemId: this.params.formItemId,
            formItemName: this.params.formItemName,
            templateParamId: this.params.templateParamId,
            paramName: this.params.paramName,
          };
          this.params.workFormItemRelationDtoList.push(obj);
          this.getProcessViewSave();
        } else {
          return false;
        }
      });
    },
    formItemVosChange(val) {
      this.params.formItemName = this.processFormItemVos.find(item => item.formItemId == this.params.formItemId).formItemName;
    },
    ParamVosChange(val) {
      this.params.paramName = this.workSheetTemplateParamVos.find(item => item.templateParamId == this.params.templateParamId).paramName;
    },
    reset() {
      this.params.formItemId = "";
      this.params.formItemName = "";
      this.params.templateParamId = "";
      this.params.paramName = "";
    }
  }

}
</script>
<style scoped lang="scss">
.condition_container {
  .main {
    height: 350px;
    padding: 15px;
    box-sizing: border-box;
  }

  .footer {
    height: 200px;
    padding: 0 15px 15px 15px;
    box-sizing: border-box;
  }

  .footer-box {
    height: 100%;
    border: 1px solid #eee;
    padding: 15px;
    box-sizing: border-box;
    overflow: auto;
  }

  .el-form {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .radio-box {
    border: 1px solid #eee;
    width: 250px;
    height: 300px;
    overflow: auto;
  }

  .radio-box p {
    padding: 10px 15px;
    font-size: 14px;
    border-bottom: 1px solid #eee;
    font-weight: bold;
  }

  .radio-ul {
    display: flex;
    flex-direction: column;
    padding: 15px;
  }

  .radio-ul .el-radio {
    margin: 5px 0;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #409EFF;
  }

  .tag-item {
    margin: 0 15px 15px 0;
  }
}
</style>