<template>
    <div class="people-list">
        <!-- 查詢 -->
        <section class="table-search">
            <div class="search-L">
                <el-select v-model="tableParams.workSheetTypeId" placeholder="请选择工单类型" @change="reload" clearable>
                    <el-option v-for="item in workTypeList" :key="item.workSheetTypeId" :label="item.workSheetName"
                        :value="item.workSheetTypeId">
                    </el-option>
                </el-select>
            </div>
            <div class="search-R">
                <ModelInfo :title="'新增工单模板'" :workTypeList="workTypeList" @update="reload" />
            </div>
        </section>
        <!-- 表格 -->
        <Table :tableData="tableData" :column="workModelColumns" border :headerCellStyle="headerCellStyle">
            <template slot-scope="scope" slot="operation">
                <ModelInfo :title="'编辑'" :row="scope.row" :workTypeList="workTypeList"  @update="reload" />
                <SortTable :row="scope.row" />
                <Association :row="scope.row" />
                <Condition :row="scope.row" />
                <el-button type="text" size="medium" @click.stop="onDel(scope.row)">删除</el-button>
            </template>
        </Table>
        <!-- 分页 -->
        <section class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.limit" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </section>
    </div>
</template>

<script>
import { workTypeList } from "@/api/examine/type"
import { getWorkSheetTemplateList,deleteWorkSheetTemplate } from "@/api/examine/model"
import ModelInfo from "./child/modelInfo";
import SortTable from "./child/sortTable";
import Association from "./child/association";
import Condition from "./child/condition";
import { workModelColumns } from "@/db/tableColumns";
import { ObjToOpt } from "@/utils/utils";
import { workOrderType } from "@/db/objs";
export default {
    components: {
        ModelInfo, SortTable, Association,
        Condition
    },
    data() {
        return {
            workModelColumns,
            workOrderType,
            ObjToOpt,
            headerCellStyle: {
                background: '#FAFAFA',
                color: '#000000',
            },
            // table表格
            currentPage: 1, // 当前页
            total: 0, // 总条数  
            tableData: [],//表格数据
            tableParams: {
                page: 1,
                limit: 10,
                kw: "",//查询关键字
                workSheetTypeId: "",//店铺ID
            },
            // 弹框
            editShow: false, //弹框开关
            editTitle: "",//弹框标题
            workTypeList: [],//工单类型下拉选择项

        }
    },
    created() {

    },
    mounted() {
        this.getTypeList();// 【请求】工单类型下拉选择项
        this.getTableData();// 【请求】表格数据
    },
    methods: {
        // 【请求】获取工单类型下拉选择项
        getTypeList() {
            let data = {
                page: 1,
                pageSize: 1000000
            };
            workTypeList(data).then((res) => {
                if (res.isSuccess == "yes") {
                    this.workTypeList = res.data.list;
                }
            });
        },
        // 分页查询工单模板
        getTableData() {
            getWorkSheetTemplateList(this.tableParams).then(res => {
                if (res.isSuccess == "yes") {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                }
            })
        },
        // 【监听】表格重载
        reload() {
            this.tableParams.page = 1;
            this.currentPage = 1;
            console.log('reload')
            this.getTableData();
        },
        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.getTableData();
        },

        // 【监听】表格条数点击
        onSizeChange(limit) {
            this.tableParams.limit = limit;
            this.reload();
        },
        onDel(row) {
            this.$confirm('是否确定删除该数据?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteWorkSheetTemplate({templateId: row.templateId}).then(res => {
                    if (res.isSuccess == "yes") {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getTableData();
                    }
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.people-list {
    padding: .15rem;
}

.table-footer {
    margin-top: .1rem;
    display: flex;
    justify-content: flex-end;
}

.table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: .15rem;
}

.search-L {
    display: flex;
}

.form-footer {
    display: flex;
    justify-content: center;
}

.code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.clear-head {
    width: .28rem;
    height: .28rem;
    border-radius: 50%;
    margin-right: .1rem;
}
</style>