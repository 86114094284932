<template>
    <div style="display: inline-block;margin-right: 10px;">
        <el-button type="text" size="medium" @click.stop="onEdit" v-if="title == '编辑'">{{ title }}</el-button>
        <el-button type="primary" size="medium" @click.stop="onAdd" v-else>{{ title }}</el-button>
        <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="70%"
            center>
            <!-- 表单提交 -->
            <el-form :model="workSheetForm" ref="form-ref" label-width="120px" :rules="workSheetRules">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="工单类型" prop="workSheetTypeId">
                    <el-cascader :options="workTypeList" :show-all-levels="false"
                        v-model="workSheetForm.workSheetTypeId" :props="workTypeProps" @change="workTypeChange"
                        ref="workSheetType"></el-cascader>
                </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="模板名称" prop="workSheetName"
                    :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]">
                    <el-input v-model.trim="workSheetForm.workSheetName" autocomplete="off" maxlength="20"
                        placeholder="请输入名称"></el-input>
                </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="关联审批流程" prop="processId">
                    <el-select v-model="workSheetForm.processId" placeholder="请选择审批流程">
                        <el-option v-for="item in processList" :key="item.processId" :label="item.processName"
                            :value="item.processId">
                        </el-option>
                    </el-select>
                </el-form-item>
                    </el-col>
                </el-row>
              
              

              
            </el-form>
            <div style="display: flex;justify-content: space-between;margin-bottom: 10px;">
                <span style="color: #000;">配置参数：</span>
                <el-button type="primary" plain size="medium"
                    @click.stop="addparams('form-workSheetForm-ref')">新增参数</el-button>
            </div>
            <Table :tableData="tableData" :column="modelParamColumns" :headerCellStyle="headerCellStyle">
                <template slot-scope="scope" slot="paramTypeSlot">
                    <span>{{ paramType[scope.row.paramType] }}</span>
                </template>
                <template slot-scope="scope" slot="inputTypeSlot">
                    <span>{{ inputTypeObj[scope.row.inputType] }}</span>
                </template>
                <template slot-scope="scope" slot="requiredSlot">
                    <span>{{ scope.row.required ? '是' : '否' }}</span>
                </template>
                <template slot-scope="scope" slot="operation" v-if="!scope.row.paramCode">
                    <el-button type="text" size="medium" @click.stop="editParams(scope)">编辑</el-button>
                    <el-button type="text" size="medium" @click.stop="onDel(scope)">删除</el-button>
                </template>
            </Table>
            <div slot="footer">
                <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="editParamsTitle" :visible.sync="editParamsShow" :close-on-click-modal="false" append-to-body
            width="30%" center @close="reset('form-workSheetForm-ref')">
            <el-form :model="paramsForm" ref="form-workSheetForm-ref" :rules="paramsRules" label-width="140px"
                style="display: block;">
                <el-form-item label="参数名称" prop="paramName"
                    :rules="[{ required: true, message: '请输入参数名称', trigger: 'blur' }]">
                    <el-input v-model.trim="paramsForm.paramName" autocomplete="off" maxlength="20"
                        placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="参数类型" prop="paramType">
                    <el-select v-model="paramsForm.paramType" placeholder="请选择参数类型" @change="changeParamType">
                        <el-option v-for="item in ObjToOpt(paramType)" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <template v-if="paramsForm.paramType && paramsForm.paramType != ''">
                    <div style="margin-left: 70px;margin-bottom: 20px;font-weight: 700;">参数属性</div>
                    <el-form-item label="类型" prop="inputType">
                        <el-select v-model="paramsForm.inputType" placeholder="请选择输入类型"
                            :disabled="[3, 4].includes(paramsForm.paramType)" @change="$forceUpdate()">
                            <el-option v-for="item in ObjToOpt(inputTypeOpt[paramsForm.paramType])" :label="item.label"
                                :value="item.value" :key="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="上传文件类型" prop="uploadType" v-if="paramsForm.paramType == 4" :rules="[{ required: true, message: '请选择文件类型', trigger: 'change'}]">
                        <el-select v-model="paramsForm.uploadType" placeholder="请选择文件类型">
                            <el-option v-for="item in ObjToOpt(fileType)" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="限制数量" prop="limitLength" v-if="paramsForm.paramType == 4">
                        <el-input v-model="paramsForm.limitLength" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="限制范围" prop="minlength" v-if="paramsForm.paramType == 1">
                        <div style="display: flex;">
                            <el-input v-model="paramsForm.minlength" placeholder="最小值"
                                style="width: 50%;"></el-input><span style="margin: 0 10px;"> - </span>
                            <el-input v-model="paramsForm.maxlength" placeholder="最大值" style="width: 50%;"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="是否可搜索" prop="canSearch" v-if="paramsForm.paramType == 2">
                        <el-select v-model="paramsForm.canSearch" placeholder="请选择类型">
                            <el-option label="是" :value="true"></el-option>
                            <el-option label="否" :value="false"></el-option>
                        </el-select>

                    </el-form-item>
                    <el-form-item label="占位文本" prop="placeholder" v-if="[1, 2, 3].includes(paramsForm.paramType)">
                        <el-input v-model.trim="paramsForm.placeholder" autocomplete="off" maxlength="20"
                            placeholder="请输入占位文本"></el-input>
                    </el-form-item>
                    <el-form-item label="是否禁用" prop="disabled">
                        <el-select v-model="paramsForm.disabled" placeholder="请选择">
                            <el-option label="是" :value="true"></el-option>
                            <el-option label="否" :value="false"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否可选当日之前" prop="dateLimit" v-if="paramsForm.paramType == 3">
                        <el-select v-model="paramsForm.dateLimit" placeholder="请选择">
                            <el-option label="是" :value="true"></el-option>
                            <el-option label="否" :value="false"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="默认值" prop="defaultVal" v-if="[1, 2].includes(paramsForm.paramType)">
                        <el-input v-model.trim="paramsForm.defaultVal" autocomplete="off" maxlength="20"
                            placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="是否必填" prop="required" v-if="[1, 2, 3].includes(paramsForm.paramType)">
                        <el-select v-model="paramsForm.required" placeholder="请选择">
                            <el-option label="是" :value="true"></el-option>
                            <el-option label="否" :value="false"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="数据源" prop="sourceType" v-if="paramsForm.paramType == 2"
                        :rules="[{ required: true, message: '请选择数据源', trigger: 'change' }]">
                        <el-select v-model="paramsForm.sourceType" placeholder="请选择">
                            <el-option v-for="item in ObjToOpt(dataSource)" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="数据源参数" prop="sourceEvent" v-if="paramsForm.paramType == 2"
                        :rules="[{ required: true, message: '请填写数据源参数', trigger: 'blur' }]">
                        <el-input v-model.trim="paramsForm.sourceEvent" autocomplete="off" maxlength="100"
                            placeholder="请填写数据源参数" clearable></el-input>
                    </el-form-item>
                </template>
            </el-form>
            <div slot="footer">
                <el-button type="primary" @click="onSubmitParams('form-workSheetForm-ref')">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    ObjToOpt,
} from "@/utils/utils"
import { modelParamColumns } from "@/db/tableColumns"
import {
    WOT, paramType, inputTypeOpt, inputTypeObj, dataSource, fileType
} from "@/db/objs"
import { getWorkTypeTree, getWorkSheetFlow, addWorkSheetTemplate, updateWorkSheetTemplate, getWorkSheetTemplateParams, getWorkSheetTemplateInfo } from "@/api/examine/model"
export default {
    props: ['title', 'row'],
    data() {
        return {
            ObjToOpt, WOT, modelParamColumns, paramType, inputTypeOpt, inputTypeObj, dataSource, fileType,
            editShow: false,
            workTypeProps: {
                emitPath: false,
                label: 'workSheetName',
                value: 'workSheetTypeId',
                children: 'children',
            },
            headerCellStyle: {
                background: '#FAFAFA',
                color: '#000000',
            },
            workSheetForm: {},
            editTitle: '新建工单模板',
            processList: [],//关联审批流程配置列表
            tableData: [],
            workSheetRules: {
                workSheetTypeId: [
                    { required: true, message: '请选择工单类型', trigger: 'change' }
                ],
                workSheetName: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                processId: [
                    { required: true, message: '请选择审批流程', trigger: 'change' }
                ]
            },
            editParamsTitle: '新增参数',
            editParamsShow: false,
            paramsForm: {
                disabled: false,
                inputType: '',
                required: false,
            },
            workTypeList: [],
            paramsRules: {
                paramName: [
                    { required: true, message: '请输入参数名称', trigger: 'blur' }
                ],
                paramType: [
                    { required: true, message: '请选择参数类型', trigger: 'change' }
                ],
                inputType: [
                    { required: true, message: '请选择输入类型', trigger: 'change' }
                ],
                placeholder: [
                    { required: true, message: '请输入占位文本', trigger: 'blur' }
                ],
             

            },
            currentIndex: -1,

        }
    },
    created() {

    },
    methods: {
        onAdd() {
            this.editShow = true
            this.getWorkTypeTree()
            this.editTitle = '新建工单模板'
        },
        onEdit() {
            this.editShow = true
            this.getWorkTypeTree()
            this.editTitle = '编辑工单模板'
            this.getWorkSheetFlow(this.row.workSheetType)
            this.getWorkSheetTemplateDetail(this.row.templateId)
        },
        onDel(scope) {
            this.tableData.splice(scope.index, 1)
        },
        changeParamType(val) {
            switch (val) {
                case 1:
                    this.paramsForm.inputType = ''
                    break;
                case 2:
                    this.paramsForm.inputType = 5
                    this.paramsForm.canSearch = false
                    this.paramsForm.disabled = false
                    this.paramsForm.required = false
                    break;
                case 3:
                    this.paramsForm.inputType = 7
                    this.paramsForm.dateLimit = true
                    this.paramsForm.disabled = false
                    this.paramsForm.required = false
                    break;
                case 4:
                    this.paramsForm.inputType = 8
                    this.paramsForm.required = false
                    break;
            }
        },
        addparams(formName) {
            this.editParamsShow = true
            this.$refs[formName].clearValidate()
            this.currentIndex = -1

            this.editParamsTitle = '新增参数'
        },
        editParams(scope) {
            this.editParamsShow = true
            this.editParamsTitle = '编辑参数'
            this.paramsForm = scope.row
            this.currentIndex = scope.index
        },
        onSubmitParams(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.currentIndex > -1) {
                        this.tableData.splice(this.currentIndex, 1, this.paramsForm)
                    } else {
                        this.tableData.push(this.paramsForm)

                    }
                    this.editParamsShow = false
                } else {
                    return false;
                }
            })
        },
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.workSheetForm.templateId) {
                        this.editWorkSheetTemplate()
                    } else {
                        this.addWorkSheetTemplate()
                    }
                } else {
                    return false;
                }
            })
        },
        // 获取工单类型树形
        getWorkTypeTree() {
            getWorkTypeTree().then(res => {
                this.workTypeList = res.data
            })
        },
        // 获取关联审批流程
        getWorkSheetFlow(processType) {
            getWorkSheetFlow({ processType: processType }).then(res => {
                this.processList = res.data
            })
        },
        // 工单类型改变事件
        workTypeChange(val) {
            const node = this.$refs.workSheetType.getCheckedNodes()
            this.workSheetForm.workSheetType = node[0].data.attributionType
            this.getWorkSheetFlow(node[0].data.attributionType)
            this.getTemplateParam(node[0].data.attributionType)
        },
        // 添加工单模板
        addWorkSheetTemplate() {
            this.workSheetForm.templateParamDtoList = this.tableData
            addWorkSheetTemplate(this.workSheetForm).then(res => {
               if(res.isSuccess == 'yes'){
                this.$emit('update')
                this.$refs['form-ref'].resetFields()
                this.editShow = false
               }
            })
        },
        // 编辑工单模板
        editWorkSheetTemplate() {
            this.workSheetForm.templateParamDtoList = this.tableData
            updateWorkSheetTemplate(this.workSheetForm).then(res => {
               if(res.isSuccess == 'yes'){
                this.$emit('update')
                this.$refs['form-ref'].resetFields()
                this.editShow = false
               }

            })
        },
        // 根据工单类型返回模板基础参数
        getTemplateParam(workSheetType) {
            getWorkSheetTemplateParams({ workSheetType: workSheetType }).then(res => {
                this.tableData = res.data
            })
        },
        // 工单模板详情
        getWorkSheetTemplateDetail(templateId) {
            getWorkSheetTemplateInfo({ templateId: templateId }).then(res => {
                this.workSheetForm = res.data
                this.tableData = res.data.workSheetTemplateParamVoList
            })
        },
        reset(formName) {
            this.$refs[formName].clearValidate()
            this.paramsForm = {
                disabled: false,
                inputType: '',
                required: false,
            }
        }

    }

}
</script>

<style></style>