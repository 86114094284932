<template>
    <div class="sort-table" style="display: inline-block;margin-right: 10px;">
        <el-button type="text" size="medium" @click.stop="onEdit()">参数排序</el-button>
        
        <el-dialog :title="'参数排序'" :visible.sync="editParamsShow" :close-on-click-modal="false" append-to-body
            width="50%" center>
            <div style="margin-bottom: 10px;">
                <span><span style="color: red;margin-right: 6px;">*</span>请拖动行进行排序</span>
            </div>
            <el-table :data="tableData" id="paramsSortTable" ref="sortTable" style="width: 100%" row-key="id" border :headerCellStyle="headerCellStyle">
                <el-table-column label="参数名称" prop="paramName">
                </el-table-column>
                <el-table-column label="参数类型">
                    <template slot-scope="scope">
                        <span>{{ paramType[scope.row.paramType] }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="属性类型">
                    <template slot-scope="scope">
                        <span>{{ inputTypeObj[scope.row.inputType] }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="是否必填">
                    <template slot-scope="scope">
                        <span>{{ scope.row.required ? '是' : '否' }}</span>
                    </template>
                </el-table-column>

            </el-table>

        </el-dialog>

    </div>
</template>
<script>
import {
    ObjToOpt,
} from "@/utils/utils"
import Sortable from 'sortablejs'
import { modelParamColumns } from "@/db/tableColumns"
import {
    paramType, yesOrNo, inputTypeObj
} from "@/db/objs"
import {
    sortWorkSheetTemplate, saveWorkSheetTemplateSort
} from "@/api/examine/model"
export default {
    props: ['row'],
    data() {
        return {
            ObjToOpt, paramType, yesOrNo, inputTypeObj, modelParamColumns,
            tableData: [],
            headerCellStyle: {
                background: '#FAFAFA',
                color: '#000000',
            },
            editParamsShow: false,
        }
    },
    mounted() {

    },
    methods: {
        onEdit() {
            this.editParamsShow = true
            this.sortWorkSheetTemplate()

        },
        initSort() {
            const el = document.querySelectorAll('#paramsSortTable > .el-table__body-wrapper > table > tbody')[0]
            // const sortable = new Sortable(el, options);
            // 根据具体需求配置options配置项
            const sortable = new Sortable(el, {
                // number 定义鼠标选中列表单元可以开始拖动的延迟时间；
                delay: 0,
                onEnd: (evt) => { // 监听拖动结束事件
                    // 我们有了 evt.oldIndex 和 evt.newIndex 这两个参数做索引，我们可以根据绑定在表格上面的 data 这个 Array 找到两个相应的记录。就可以针对数据进行操作啦。
                    // 下面将拖拽后的顺序进行修改
                    const currRow = this.tableData.splice(evt.oldIndex, 1)[0]
                    this.tableData.splice(evt.newIndex, 0, currRow)
                    let newArray = this.tableData.slice(0);//数组浅拷贝，复制数组
                    this.tableData = []
                    this.$nextTick(() => {
                        this.tableData = newArray;
                        this.sortLevelEquity()
                    })
                }
            })
        },
        // 工单模板参数排序回显
        sortWorkSheetTemplate() {
            sortWorkSheetTemplate({ templateId: this.row.templateId }).then(res => {
                this.tableData = res.data
                this.$nextTick(() => {
                    this.initSort()
                })
            })
        },
        // 工单模板参数排序保存
        sortLevelEquity() {
            const data = {
                templateId: this.row.templateId,
                templateParamDtoList: this.tableData
            }
            saveWorkSheetTemplateSort(data).then(res => {
                this.$message.success('保存成功')
            })
        }

    }

}
</script>
<style>
.el-table__row {
    cursor: move;
}
</style>