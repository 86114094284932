// 工单模板相关接口
import request from "@/utils/request";
// 获取工单类型树形结构
export function getWorkTypeTree() {
  return request("post", "/apm/workSheet/template/workSheetTypes");
}
// 获取工单模板列表
export function getWorkSheetTemplateList(data) {
  return request("post", "/apm/workSheet/template/page", data);
}
// 添加工单模板
export function addWorkSheetTemplate(data) {
  return request("post", "/apm/workSheet/template/add", data);
}
// 获取关联审批流程
export function getWorkSheetFlow(data) {
  return request("get", "/apm/workSheet/template/workSheetProcess", data);
}
// 根据工单类型返回模板基础参数
  export function getWorkSheetTemplateParams(data) {
    return request("post", "/apm/workSheet/template/baseParamByType", data);
  }
  // 更新工单模板
  export function updateWorkSheetTemplate(data) {
    return request("post", "/apm/workSheet/template/update", data);
  }
  // 工单模板查询
  export function getWorkSheetTemplateInfo(data) {
    return request("post", "/apm/workSheet/template/find", data);
  }
  // 删除工单模板
  export function deleteWorkSheetTemplate(data) {
    return request("post", "/apm/workSheet/template/delete", data);
  }
  // 工单模板参数排序回显
  export function sortWorkSheetTemplate(data) {
    return request("post", "/apm/workSheet/template/inputSortView", data);
  }
  // 工单模板参数排序保存
  export function saveWorkSheetTemplateSort(data) {
    return request("post", "/apm/workSheet/template/inputSort", data);
  }
  // 审批参数排序回显
  export function sortWorkSheetApprove(data) {
    return request("post", "/apm/workSheet/template/approveSortView", data);
  }
  // 审批参数排序保存
  export function saveWorkSheetApproveSort(data) {
    return request("post", "/apm/workSheet/template/viewSort", data);
  }
  // 回显关联审批条件
  export function getWorkSheetApproveCondition(data) {
    return request("post", "/apm/workSheet/template/relationProcessView", data);
  }
  // 保存关联审批条件
  export function saveWorkSheetApproveCondition(data) {
    return request("post", "/apm/workSheet/template/relationProcess", data);
  }
  // 按类别查询工单模板列表
  export function getWorkSheetTemplateListByType(data) {
    return request("post", "/apm/workSheet/template/list", data);
  }