<template>
    <div class="sort-table" style="display: inline-block;margin-right: 10px;">
        <el-button type="text" size="medium" @click.stop="onEdit()">关联审批详情</el-button>
        <el-dialog :title="'关联审批详情'" :visible.sync="editParamsShow" :close-on-click-modal="false" append-to-body
            width="50%" center>
            <div class="head">
                <span><span style="color: red;margin-right: 6px;">*</span>请拖动行进行排序</span>
                <el-button type="primary" size="medium" plain @click.stop="add()">添加关联字段</el-button>
            </div>
            <el-table :data="tableData" id="associationSortTable" ref="sortTable" style="width: 100%" row-key="id" border :headerCellStyle="headerCellStyle">
                <el-table-column label="参数名称" prop="paramName">
                </el-table-column>
                <el-table-column label="参数类型">
                    <template slot-scope="scope">
                        <span>{{ ObjToOpt(paramType)[scope.row.paramType - 1].label }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="属性类型">
                    <template slot-scope="scope">
                        <span>{{ ObjToOpt(inputTypeObj)[scope.row.inputType - 1].label }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="是否必填">
                    <template slot-scope="scope">
                        <span>{{ scope.row.required ? '是' : '否' }}</span>
                    </template>
                </el-table-column>
            </el-table>

        </el-dialog>
        <el-dialog :title="'选择关联字段'" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="50%"
            center>
            <div class="box">
                <el-checkbox-group v-model="formParams.fields" size="small">
                    <el-checkbox class="field-item" v-for="item in fieldList" :key="item.templateParamId" :label="item"
                        border>{{ item.paramName }}</el-checkbox>
                </el-checkbox-group>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="pushCode">确定</el-button>
            </div>
        </el-dialog>


    </div>
</template>
<script>
import {
    ObjToOpt,
} from "@/utils/utils"
import Sortable from 'sortablejs'
import { modelParamColumns } from "@/db/tableColumns"
import {
    paramType, yesOrNo, inputTypeObj
} from "@/db/objs"
import {
    sortWorkSheetApprove, saveWorkSheetApproveSort
} from "@/api/examine/model"
export default {
    props: ['row'],
    data() {
        return {
            ObjToOpt, paramType, yesOrNo, inputTypeObj, modelParamColumns,
            tableData: [],
            editParamsShow: false,
            editShow: false,
            formParams: {
                fields: []
            },
            headerCellStyle: {
                background: '#FAFAFA',
                color: '#000000',
            },
            fieldList: []//全部参数
        }
    },
    mounted() {

    },
    methods: {
        onEdit() {
            this.editParamsShow = true
            this.sortWorkSheetApprove()

        },
        initSort() {
            const el = document.querySelectorAll('#associationSortTable > .el-table__body-wrapper > table > tbody')[0]
            // const sortable = new Sortable(el, options);
            // 根据具体需求配置options配置项
            const sortable = new Sortable(el, {
                // number 定义鼠标选中列表单元可以开始拖动的延迟时间；
                delay: 0,
                onEnd: (evt) => { // 监听拖动结束事件
                    // 我们有了 evt.oldIndex 和 evt.newIndex 这两个参数做索引，我们可以根据绑定在表格上面的 data 这个 Array 找到两个相应的记录。就可以针对数据进行操作啦。
                    // 下面将拖拽后的顺序进行修改
                    const currRow = this.tableData.splice(evt.oldIndex, 1)[0]
                    this.tableData.splice(evt.newIndex, 0, currRow)
                    let newArray = this.tableData.slice(0);//数组浅拷贝，复制数组
                    this.tableData = []
                    this.$nextTick(() => {
                        this.tableData = newArray;
                        this.sortLevelEquity(this.tableData)
                    })
                }
            })
        },
        add() {
            this.editShow = true
            this.formParams.fields = this.tableData.filter(i => i.preview)

        },
        pushCode() {
            this.sortLevelEquity(this.formParams.fields, true)

        },
        // 工单模板参数排序回显
        sortWorkSheetApprove() {
            sortWorkSheetApprove({ templateId: this.row.templateId }).then(res => {
                this.tableData = res.data.filter(i => i.preview)
                this.fieldList = res.data; //获取全部参数
                this.$nextTick(() => {
                    this.initSort()
                })
            })
        },
        // 工单模板参数排序保存
        sortLevelEquity(arr, isCloseDialog) {
            const data = {
                templateId: this.row.templateId,
                templateParamDtoList: arr
            }
            saveWorkSheetApproveSort(data).then(res => {
                this.$message.success('保存成功')
                this.sortWorkSheetApprove()
                if (isCloseDialog) {
                    this.editShow = false
                }
            })
        }

    }

}
</script>
<style lang="scss" scoped>
.el-table__row {
    cursor: move;
}

.head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.box {
    padding: 10px;
    border: 1px solid #eee;
    display: flex;
    flex-wrap: wrap;

    .field-item {
        margin: 0px 15px 15px 0px !important;
    }
}
</style>